<template>
  <section>
    <div class="section-jobs section" id="jobs">
      <JobsHero />
      <div class="jobsList" v-bind="initData">
        <VacancyCard class="jobsList-card" v-for="vacancy in vacancies" :key="vacancy._id" :vacancy="vacancy" />
      </div>
    </div>
  </section>
</template>

<script>
import JobsHero from "./jobsHero.vue";
import VacancyCard from "./vacancyCard.vue";

export default {
  components: { JobsHero, VacancyCard },
  data: () => {
    return {
      vacancies: {},
    };
  },
  methods: {
    getVacancies: async function () {
      const host = `${process.env.VUE_APP_API_ADMIN}vacancy`;
      const { data } = await this.$api.get(host);
      this.vacancies = data.filter((vacancy) => vacancy.isShow).reverse();
    },
  },
  computed: {
    initData: async function () {
      await this.getVacancies();
    },
  },
  mounted: async function () {
    this.$root.$on("refreshlang", (data) => {
      this.getVacancies();
    });
  },
};
</script>

<style lang="scss">
.section-jobs {
  padding: 0 $mpadding;
  padding-top: 70px;
}
.jobsList {
  min-height: 65vh;
  display: grid;
  gap: 25px;
  margin: 20px 0;
  &-card {
    width: 100%;
  }

  @media (min-width: $pocket_width) {
    grid-template-columns: repeat(2, auto);
    justify-content: center;
  }

  @media (min-width: $desktop_width) {
    grid-template-columns: 968px;
  }
}
</style>
